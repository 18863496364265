import React from "react";
import "react-circular-progressbar/dist/styles.css";
import "./DayPage.css";
import { useActivity } from "../../contexts/activityContext";
import Stats from "../common/Stats";
import AddActivity from "../common/AddActivity";
import DateHeader from "../common/DateHeader";
import { useCalendar } from "../../contexts/calendarContext";
import { addDays, subDays } from "date-fns";

const DayPage: React.FC = () => {
  const { activityTracker, addReadingTimeAsync, addExerciseTimeAsync } = useActivity();
  const { selectedDate, setSelectedDate } = useCalendar();

  const handlePrevDayClick = () => {
    setSelectedDate(subDays(selectedDate, 1));
  };

  const handleNextDayClick = () => {
    setSelectedDate(addDays(selectedDate, 1));
  };

  const addReadingTime = async (minutes: number): Promise<void> => {
    await addReadingTimeAsync(minutes, selectedDate);
  };

  const addExerciseTime = async (minutes: number): Promise<void> => {
    await addExerciseTimeAsync(minutes, selectedDate);
  };

  if (!activityTracker) {
    return null;
  }

  const day = activityTracker.getDay(selectedDate);
  const readingPercentage = (day.readingMinutes / 60) * 100;
  const exercisePercentage = (day.exerciseMinutes / 60) * 100;

  return (
    <div className="dayPage">
      <DateHeader selectedDay={selectedDate} onPrevDayClick={handlePrevDayClick} onNextDayClick={handleNextDayClick} />
      <div className="statistics">
        <Stats title="Reading" minutes={day.readingMinutes} percentage={readingPercentage} />
        <Stats title="Exercise" minutes={day.exerciseMinutes} percentage={exercisePercentage} />
      </div>

      <div className="activityInput">
        <AddActivity title="Reading" onButtonClick={addReadingTime} />
        <AddActivity title="Exercise" onButtonClick={addExerciseTime} />
      </div>
    </div>
  );
};

export default DayPage;

import { useTranslation } from "react-i18next";

import "./ActivityButton.css";

interface Props {
  onClick: () => void;
  label: string;
  buttonType: "reading" | "exercise";
  disabled?: boolean;
}

const ActivityButton: React.FC<Props> = ({ onClick, label, buttonType, disabled = false }) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      onClick={onClick}
      className={`activityTypeButton ${buttonType}-button ${disabled ? "disabled-button" : ""}`} // add a new class if the button is disabled
      disabled={disabled}
    >
      {t(label) || label}
    </button>
  );
};

export default ActivityButton;

import React from "react";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer } from "recharts";

import "./Chart.css";

type ChartData = {
  name: string;
  minutes: number;
}[];

type ChartProps = {
  title: string;
  data: ChartData;
  color: string;
};

const Chart: React.FC<ChartProps> = ({ title, data, color }) => {
  return (
    <div className="chartContainer">
      <h2 className="lively">{title}</h2>
      <ResponsiveContainer width="95%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="minutes" fill={color} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;

import "./WeekPage.css";
import { useActivity } from "../../contexts/activityContext";
import Stats from "../common/Stats";
import { useCalendar } from "../../contexts/calendarContext";
import WeekHeader from "../common/WeekHeader";
import Chart from "../common/Chart";
import { useTranslation } from "react-i18next";

const WeekPage: React.FC = () => {
  const { activityTracker } = useActivity();
  const { selectedWeek, setSelectedWeek } = useCalendar();
  const { t } = useTranslation();

  const handlePrevWeekClick = () => {
    setSelectedWeek(selectedWeek - 1);
  };

  const handleNextWeekClick = () => {
    setSelectedWeek(selectedWeek + 1);
  };

  if (!activityTracker) {
    return null;
  }

  const week = activityTracker.getWeek(new Date().getFullYear(), selectedWeek);
  const readingMinutes = week.readingMinutes;
  const exerciseMinutes = week.exerciseMinutes;
  const readingPercentage = (readingMinutes / (60 * 7)) * 100;
  const exercisePercentage = (exerciseMinutes / (60 * 7)) * 100;

  const readingDataThisWeek = activityTracker.getReadingDataThisWeek();
  const exerciseDataThisWeek = activityTracker.getExerciseDataThisWeek();

  return (
    <div className="weekPage">
      <WeekHeader
        selectedWeek={selectedWeek}
        onNextWeekClick={handleNextWeekClick}
        onPrevWeekClick={handlePrevWeekClick}
      />
      <div className="statistics">
        <Stats title="Reading" minutes={readingMinutes} percentage={readingPercentage} />
        <Stats title="Exercise" minutes={exerciseMinutes} percentage={exercisePercentage} />
      </div>

      <div className="chartsSection">
        <Chart title={t("Reading")} data={readingDataThisWeek} color="#7E57C2" />
        <Chart title={t("Exercise")} data={exerciseDataThisWeek} color="#FF7043" />
      </div>
    </div>
  );
};

export default WeekPage;

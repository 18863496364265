import React, { useEffect } from "react";
import { useAuth } from "../../contexts/authContext";
import { useProgress } from "../../contexts/progressContext";
import { useNavigate } from "react-router-dom";
import { HOME_PAGE } from "../../routes";
import ProgressSpinner from "../common/ProgressSpinner";

export function WithAuthAndProgress<P extends object>({
  component: WrappedComponent,
  ...props
}: P & { component: React.ComponentType }) {
  const { userHasLoggedIn } = useAuth();
  const { isInitializing } = useProgress();
  const navigate = useNavigate();

  // Perform the check for user login
  useEffect(() => {
    if (!userHasLoggedIn()) {
      navigate(HOME_PAGE);
    }
  }, [userHasLoggedIn, navigate]);

  // If the context is still initializing, return a loading spinner
  if (isInitializing()) {
    return <ProgressSpinner />;
  }

  // Otherwise, render the WrappedComponent
  return <WrappedComponent {...props} />;
}

// Usage:
// <Route path={routes.WEEK_PAGE} element={<WithAuthAndProgress component={WeekPage} />} />

import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import "./PeriodNavigation.css";

interface PeriodNavigationProps {
  isLoading: boolean;
  onPrevClick: () => void;
  onNextClick: () => void;
  isCurrentPeriod: boolean;
}

export const PeriodNavigation: FC<PeriodNavigationProps> = ({
  isLoading,
  onPrevClick,
  onNextClick,
  isCurrentPeriod,
}) => {
  return (
    <div className="periodNavigation">
      <button title="Go to previous period" disabled={isLoading} type="button" onClick={onPrevClick}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <button
        title="Go to next period"
        disabled={isLoading || isCurrentPeriod}
        type="button"
        onClick={onNextClick}
        className={isCurrentPeriod ? "disabled" : ""}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};

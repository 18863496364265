import "./ProgressSpinner.css";
import { useTranslation } from "react-i18next";

const ProgressSpinner = () => {
  const { t } = useTranslation();
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
      <p className="spinner-text">{t("Loading data")}</p>
    </div>
  );
};

export default ProgressSpinner;

import { format, startOfWeek, add } from "date-fns";
import { fi } from "date-fns/locale";

export const getWeekNumber = (date: Date): number => {
  const target = new Date(date); // clone date to avoid mutating original date object
  const dayNr = (date.getDay() + 6) % 7;
  target.setDate(target.getDate() - dayNr + 3);
  const firstThursday = target.valueOf();
  target.setMonth(0, 1);
  if (target.getDay() !== 4) {
    target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
  }
  return 1 + Math.ceil((firstThursday - target.valueOf()) / 604800000);
};

export const getWeekStartOf = (weekNumber: number): Date => {
  const now = new Date();
  const year = now.getFullYear();
  let weekStart = new Date(year, 0, 1);
  let dayOfWeek = weekStart.getDay();
  // Check if January 1 is on a Friday, Saturday, or Sunday
  if (dayOfWeek >= 5) {
    weekStart.setDate(weekStart.getDate() + (8 - dayOfWeek));
  } else {
    weekStart.setDate(weekStart.getDate() - dayOfWeek + 1);
  }
  weekStart.setDate(weekStart.getDate() + (weekNumber - 1) * 7);
  return weekStart;
};

export const getWeekEndOf = (weekNumber: number): Date => {
  const now = new Date();
  const year = now.getFullYear();
  let weekStart = new Date(year, 0, 1);
  let dayOfWeek = weekStart.getDay();
  // Check if January 1 is on a Friday, Saturday, or Sunday
  if (dayOfWeek >= 5) {
    weekStart.setDate(weekStart.getDate() + (8 - dayOfWeek));
  } else {
    weekStart.setDate(weekStart.getDate() - dayOfWeek + 1);
  }
  weekStart.setDate(weekStart.getDate() + (weekNumber - 1) * 7);
  let weekEnd = new Date(weekStart.getTime());
  weekEnd.setDate(weekEnd.getDate() + 6); // Adding 6 days to get the end of the week
  return weekEnd;
};

export const getCurrentDate = (): Date => {
  return new Date();
};

export const getCurrentWeekNumber = (): number => {
  return getWeekNumber(new Date());
};

export const getCurrentWeekDayNumber = (): number => {
  return getWeekDayNumber(new Date());
};

export const getWeekDayNumber = (date: Date): number => {
  const dayNumber = date.getDay();
  // If it's Sunday (0), return 6. Otherwise subtract 1.
  return dayNumber === 0 ? 6 : dayNumber - 1;
};

export const getWeekdays = (): string[] => {
  const startDate = startOfWeek(new Date(), { weekStartsOn: 1 }); // week starts on Monday
  return Array.from({ length: 7 }, (_, i) => format(add(startDate, { days: i }), "iiiiii", { locale: fi }));
};

export function getCurrentMonthAndYear() {
  const date = new Date();
  const month = date.getMonth() + 1; // months are 0-indexed in JavaScript
  const year = date.getFullYear();

  return { month, year };
}

export function getCurrentMonthAndYearConcatenated() {
  const date = new Date();
  const month = date.getMonth() + 1; // months are 0-indexed in JavaScript
  const year = date.getFullYear();

  return `${year}-${month}`;
}

export function getCurrentYear(): any {
  const date = new Date();
  const year = date.getFullYear();

  return year;
}

export const formatWeek = (selectedWeek: number, t: any) => {
  const weekStart = getWeekStartOf(selectedWeek);
  const weekEnd = getWeekEndOf(selectedWeek);

  const options: Intl.DateTimeFormatOptions = { year: "2-digit", month: "2-digit", day: "2-digit" };
  const weekStartText = weekStart.toLocaleDateString(undefined, options);
  const weekEndText = weekEnd.toLocaleDateString(undefined, options);

  return weekStartText + " - " + weekEndText;
};

export const isThisWeek = (selectedWeekNumber: number): boolean => {
  const now = new Date();
  return getWeekNumber(now) === selectedWeekNumber;
};

export const isThisMonth = (selectedMonth: string): boolean => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const [selectedYear, selectedMonthNumber] = selectedMonth.split("-").map(Number);
  return year === selectedYear && month === selectedMonthNumber;
};

export const isThisYear = (selectedYear: number): boolean => {
  const now = new Date();
  const year = now.getFullYear();
  return year === selectedYear;
};

import { CircularProgressbar } from "react-circular-progressbar";
import { useTranslation } from "react-i18next";

import "./Stats.css";

const Stats: React.FC<{ title: string; minutes: number; percentage: number }> = ({ title, minutes, percentage }) => {
  const { t } = useTranslation();

  return (
    <div className={`${title.toLowerCase()}Stats`}>
      <h2 className="lively">
        {t(title)}: {minutes} {t("minutes")}
      </h2>
      <CircularProgressbar value={percentage} text={`${percentage.toFixed(1)}%`} />
    </div>
  );
};

export default Stats;

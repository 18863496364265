import { Transition } from "react-transition-group";

import "./SuccessMessage.css";
import { useTranslation } from "react-i18next";

interface Props {
  isVisible: boolean;
}

const SuccessMessage: React.FC<Props> = ({ isVisible }) => {
  const { t } = useTranslation();

  return (
    <Transition in={isVisible} timeout={500}>
      {(state) => (
        <div className={`success-message transition-${state}`}>
          <p>{t("Activity added successfully")}</p>
        </div>
      )}
    </Transition>
  );
};

export default SuccessMessage;

export const msalConfig = {
  auth: {
    clientId: "8fd881d9-efa1-45eb-95eb-816bb2214a34",
    authority: "https://login.microsoftonline.com/5f21637e-367c-4bba-b6a3-38525a0bca53",
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

import "./ProgressSpinnerWithOverlay.css";

const ProgressSpinnerWithOverlay = () => (
  <div className="overlay">
    <div className="loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default ProgressSpinnerWithOverlay;

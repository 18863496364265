import { useTranslation } from "react-i18next";

import "./ActivityDurationInput.css";

interface Props {
  value: number | "";
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  error: string | undefined;
}

const ActivityDurationInput: React.FC<Props> = ({ value, handleChange, handleBlur, error }) => {
  const { t } = useTranslation();
  return (
    <div className="inputGroup">
      <label htmlFor="activityDuration" className="label">
        {t("durationMinutes") || "Duration (minutes)"}
      </label>
      <input
        type="number"
        name="activityDuration"
        value={value === "" ? "" : value}
        onChange={handleChange}
        onBlur={handleBlur}
        min="15"
        step="15"
        max="180"
        className="inputField durationInputField"
        title={t("enterActivityDuration") || "Enter activity duration"}
      />
      <input
        type="range"
        min="15"
        max="180"
        step="15"
        value={value || "15"}
        onChange={handleChange}
        className="slider durationSlider"
        title={t("adjustActivityDuration") || "Adjust activity duration"}
      />
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default ActivityDurationInput;

function NotFoundPage() {
  return (
    <div>
      <h1>Not Found</h1>
      <p>The page you requested could not be found.</p>
    </div>
  );
}

export default NotFoundPage;

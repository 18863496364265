import { PeriodDto, PeriodType, TrendPeriodDto, TrendsDto, UserActivityTotalsDto } from "../../../common/model/trends";
export type { PeriodType };

export class UserActivityTotals {
  constructor(
    public userId: string,
    public displayName: string,
    public readingMinutes: number,
    public movementMinutes: number
  ) {}

  static fromDto(userActivityTotalsDto: UserActivityTotalsDto): UserActivityTotals {
    return new UserActivityTotals(
      userActivityTotalsDto.userId,
      userActivityTotalsDto.displayName,
      userActivityTotalsDto.readingMinutes,
      userActivityTotalsDto.movementMinutes
    );
  }
}

export class Period {
  constructor(public type: PeriodType, public value: string) {}

  static fromDto(periodDto: PeriodDto): Period {
    return new Period(periodDto.type, periodDto.value);
  }
}

export class TrendPeriod {
  constructor(public period: Period, public users: UserActivityTotals[]) {}

  public hasData(): boolean {
    return this.users.length > 0;
  }

  static fromDto(trendPeriodDto: TrendPeriodDto): TrendPeriod {
    const period = new Period(trendPeriodDto.period.type, trendPeriodDto.period.value);
    const users = trendPeriodDto.users.map(UserActivityTotals.fromDto);
    return new TrendPeriod(period, users);
  }
}

export class Trends {
  constructor(
    public date?: TrendPeriod,
    public week?: TrendPeriod,
    public month?: TrendPeriod,
    public year?: TrendPeriod
  ) {}

  static fromDto(trendsDto: TrendsDto): Trends {
    return new Trends(
      trendsDto.date ? TrendPeriod.fromDto(trendsDto.date) : undefined,
      trendsDto.week ? TrendPeriod.fromDto(trendsDto.week) : undefined,
      trendsDto.month ? TrendPeriod.fromDto(trendsDto.month) : undefined,
      trendsDto.year ? TrendPeriod.fromDto(trendsDto.year) : undefined
    );
  }
}

import { Activity as ActivityDto, ActivityType } from "../../../common/model/activity";
import * as timeUtils from "../utils/timeUtils";

class Activity {
  weekNumber: number;

  constructor(
    public userId: string,
    public activityId: string | null,
    public type: string,
    public datetime: Date,
    public duration: number
  ) {
    this.weekNumber = this.getWeekNumber();
  }

  getYear(): number {
    return this.datetime.getFullYear();
  }

  getWeekNumber(): number {
    return timeUtils.getWeekNumber(this.datetime);
  }

  getDayNumber() {
    return timeUtils.getWeekDayNumber(this.datetime);
  }

  getWeekDayNumber(): number {
    return timeUtils.getWeekDayNumber(this.datetime);
  }

  toDto(): ActivityDto {
    return {
      userId: this.userId,
      activityId: this.activityId,
      type: this.type as ActivityType,
      datetime: this.datetime.toISOString(),
      duration: this.duration,
    };
  }

  static parseDate(input: string): Date {
    if (input.includes("-")) {
      // ISO date format contains hyphens
      return new Date(input); // JavaScript's Date constructor can parse ISO dates
    } else {
      const [day, month, year] = input.split(".");
      return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    }
  }

  static createNewActivity(userId: string, type: string, durationInMinutes: number, date?: Date): Activity {
    switch (type) {
      case "READING":
        return Reading.createNewReading(userId, durationInMinutes, date);
      case "MOVEMENT":
        return Movement.createNewMovement(userId, durationInMinutes, date);
      default:
        throw new Error(`Unknown activity type: ${type}`);
    }
  }

  static fromDto(a: ActivityDto): any {
    switch (a.type) {
      case "READING":
        return new Reading(a.userId, a.activityId, Activity.parseDate(a.datetime), a.duration);
      case "MOVEMENT":
        return new Movement(a.userId, a.activityId, Activity.parseDate(a.datetime), a.duration);
      default:
        throw new Error(`Unknown activity type: ${a.type}`);
    }
  }
}

class Reading extends Activity {
  constructor(userId: string, activityId: string | null, date: Date, durationInMinutes: number) {
    super(userId, activityId, "READING", date, durationInMinutes);
  }

  static createNewReading(userId: string, durationInMinutes: number, date?: Date): Reading {
    return new Reading(userId, null, date ?? new Date(), durationInMinutes);
  }

  // Additional Reading-specific methods or properties
}

class Movement extends Activity {
  constructor(userId: string, activityId: string | null, date: Date, durationInMinutes: number) {
    super(userId, activityId, "MOVEMENT", date, durationInMinutes);
  }

  static createNewMovement(userId: string, durationInMinutes: number, date?: Date) {
    return new Movement(userId, null, date ?? new Date(), durationInMinutes);
  }

  // Additional Movement-specific methods or properties
}

export { Activity, Reading, Movement };

import { useContext, createContext, useState } from "react";
import { ReactNode } from "react";
import ActivityTracker from "../domain/ActivityTracker";
import User from "../domain/User";
import { Activity, Movement, Reading } from "../domain/Activity";
import * as apiClient from "../api/apiClient";
import { Activity as ActivityDto } from "../../../common/model/activity";
import { useAuth } from "./authContext";
import { useProgress } from "./progressContext";

interface ActivityContextType {
  activityTracker: ActivityTracker | null;
  initializeActivityTrackerAsync: (user: User) => Promise<ActivityTracker | null>;
  deleteActivityAsync: (activityId: string) => Promise<void>;
  addReadingTimeAsync: (minutes: number, date?: Date) => Promise<void>;
  addExerciseTimeAsync: (minutes: number, date?: Date) => Promise<void>;
  addActivityAsync: (activity: Activity, date?: Date) => Promise<void>;
}

export const ActivityContext: React.Context<ActivityContextType> = createContext<ActivityContextType>({
  activityTracker: null,
  initializeActivityTrackerAsync: async () => null,
  deleteActivityAsync: async () => {},
  addReadingTimeAsync: async () => {},
  addExerciseTimeAsync: async () => {},
  addActivityAsync: async () => {},
});

/**
 * @typedef ActivityContextProviderProps
 * @property {ReactNode} children - React components that will be rendered as children of the ActivityContextProviderProps.
 */
interface ActivityContextProviderProps {
  children: ReactNode;
}

export const ActivityProvider: React.FC<ActivityContextProviderProps> = ({ children }) => {
  const { acquireAccessTokenAsync } = useAuth();
  const [activityTracker, setActivityTracker] = useState<ActivityTracker | null>(null);
  const { isInitializingUserActivities, setInitializingUserActivities } = useProgress();

  const initializeActivityTrackerAsync = async (user: User) => {
    if (isInitializingUserActivities()) {
      return null;
    }

    if (activityTracker && activityTracker.user.id === user.id) {
      return activityTracker;
    }

    setInitializingUserActivities(true);
    try {
      const activities: ActivityDto[] = await apiClient.getUserActivitiesAsync(acquireAccessTokenAsync);
      const newActivityTracker = new ActivityTracker(
        user,
        activities
          .map((a) => {
            try {
              return Activity.fromDto(a);
            } catch (error: unknown) {
              // use `unknown` instead of `any` for better type safety
              if (error instanceof Error) {
                // TypeScript will now recognize `error` as type `Error` inside this block
                console.error(`Error processing activity: ${a}. Error: ${error.message}`);
              } else {
                console.error(`Error processing activity: ${a}. Error: ${String(error)}`);
              }
              return null;
            }
          })
          .filter((item) => item != null) // This will remove null items from the list (if any)
      );

      setActivityTracker(newActivityTracker);
      return newActivityTracker;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      setInitializingUserActivities(false);
    }
  };

  const deleteActivityAsync = async (activityId: string) => {
    if (activityTracker === null) throw new Error("Activity tracker is not initialized");

    await apiClient.deleteActivityAsync(acquireAccessTokenAsync, activityId);
    setActivityTracker(activityTracker.deleteActivity(activityId));
  };

  const addReadingTimeAsync = async (minutes: number, date?: Date): Promise<void> => {
    if (activityTracker === null) throw new Error("Activity tracker is not initialized");

    const newActivity: Reading = Reading.createNewReading(activityTracker!.user.id, minutes, date);
    const createdActivity = await apiClient.createActivityAsync(acquireAccessTokenAsync, newActivity.toDto());
    setActivityTracker(activityTracker.addActivity(Reading.fromDto(createdActivity)));
  };

  const addExerciseTimeAsync = async (minutes: number, date?: Date): Promise<void> => {
    if (activityTracker === null) throw new Error("Activity tracker is not initialized");

    const newActivity: Movement = Movement.createNewMovement(activityTracker!.user.id, minutes, date);
    const createdActivity = await apiClient.createActivityAsync(acquireAccessTokenAsync, newActivity.toDto());
    setActivityTracker(activityTracker.addActivity(Movement.fromDto(createdActivity)));
  };

  const addActivityAsync = async (activity: Activity): Promise<void> => {
    if (activityTracker === null) throw new Error("Activity tracker is not initialized");

    const createdActivity = await apiClient.createActivityAsync(acquireAccessTokenAsync, activity.toDto());
    setActivityTracker(activityTracker.addActivity(Activity.fromDto(createdActivity)));
  };

  //   const getUserWeeklySummaryAsync = async (user: User) => {
  //     try {
  //       const weeklySummary: WeeklySummary = await apiClient.getUserWeeklySummaryAsync(
  //         azureAd.acquireAccessTokenAsync,
  //         selectedWeekNumber
  //       );
  //       const newActivityTracker = new ActivityTracker(user);
  //       newActivityTracker.addActivities(weeklySummary.activities.map((a) => Activity.fromDto(a)));
  //       setActivityTracker(newActivityTracker);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  return (
    <ActivityContext.Provider
      value={{
        activityTracker,
        initializeActivityTrackerAsync,
        deleteActivityAsync,
        addReadingTimeAsync,
        addExerciseTimeAsync,
        addActivityAsync,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};

export const useActivity = (): ActivityContextType => {
  const context = useContext(ActivityContext);
  if (context === undefined) {
    throw new Error("useActivity must be used within an ActivityProvider");
  }
  return context;
};

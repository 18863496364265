import { useTranslation } from "react-i18next";
import TimeButtonGroup from "./TimeButtonGroup";

import "./AddActivity.css";

const AddActivity: React.FC<{ title: string; onButtonClick: (minutes: number) => void }> = ({
  title,
  onButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`${title.toLowerCase()}Input`}>
      <h3 className="lively">{t(`Add ${title} Time`)}</h3>
      <TimeButtonGroup onButtonClick={onButtonClick} />
    </div>
  );
};

export default AddActivity;

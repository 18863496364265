import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import { apiScopes } from "../apiConfig";
import { useMsal } from "@azure/msal-react";

/**
 * AuthContextType represents the shape of the context that will be provided by the AuthProvider.
 * It contains the `azureAd` object that can be used by child components to interact with Azure Active Directory.
 * @interface
 */
interface AuthContextType {
  userHasLoggedIn: () => boolean;
  handleAzureAdLoginAsync: () => Promise<void>;
  handleAzureAdLoginWithRedirect: () => Promise<void>;
  acquireAccessTokenAsync: () => Promise<string>;
  authenticationInProgress: boolean;
  account: AccountInfo | null;
}

/**
 * The AuthContext is the context that will be provided by the AuthProvider component. It can be consumed by child components
 * to get access to Azure AD functionalities.
 * @type {React.Context<AuthContextType>}
 */
export const AuthContext: React.Context<AuthContextType | undefined> = createContext<AuthContextType | undefined>(
  undefined
);

/**
 * AuthProviderProps represents the props that are required by the AuthProvider component.
 * It requires a `children` prop to render child components and a `pca` prop to provide MSAL functionalities.
 * @typedef
 * @property {ReactNode} children - React components that will be rendered as children of the AuthProvider.
 * @property {IPublicClientApplication} pca - The PublicClientApplication instance from MSAL.
 */
interface AuthProviderProps {
  children: ReactNode;
}

/**
 * The AuthProvider component is a context provider that provides Azure AD functionalities to all child components.
 * It uses the MSAL's PublicClientApplication instance to provide these functionalities.
 * @component
 * @param {AuthProviderProps} props - The props that configure the AuthProvider.
 */
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }: AuthProviderProps) => {
  const { instance, inProgress, accounts } = useMsal();
  const [account, setAccount] = useState<AccountInfo | null>(accounts[0] || null);

  const userHasLoggedIn = useCallback(() => {
    return !!account;
  }, [account]);

  const acquireAccessTokenAsync = useCallback(async (): Promise<string> => {
    if (!account) {
      throw new Error("Can't retrieve access token without an account!");
    }

    try {
      const response = await instance.acquireTokenSilent({
        scopes: apiScopes,
        account,
      });
      return response.accessToken;
    } catch (error) {
      console.error("Error acquiring access token:", error);
      throw error;
    }
  }, [instance, account]);

  const handleAzureAdLoginAsync = useCallback(async () => {
    try {
      if (!userHasLoggedIn() && inProgress === InteractionStatus.None) {
        const response = await instance.loginPopup({ scopes: apiScopes });
        const { account } = response;
        if (account) {
          setAccount(response.account);
        } else {
          throw new Error("No account found in login response!");
        }
      }
    } catch (error) {
      console.error("Error during Azure AD login:", error);
      throw error;
    }
  }, [instance, inProgress, userHasLoggedIn]);

  const handleAzureAdLoginWithRedirect = useCallback(async () => {
    try {
      if (!userHasLoggedIn() && inProgress === InteractionStatus.None) {
        await instance.loginRedirect({ scopes: apiScopes });
      }
    } catch (error) {
      console.error("Error during Azure AD login:", error);
      throw error;
    }
  }, [instance, userHasLoggedIn, inProgress]);

  useEffect(() => {
    // Default to using the first account if no account is active on page load
    if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      const selectedAccount = instance.getAllAccounts()[0];
      instance.setActiveAccount(selectedAccount);
      setAccount(selectedAccount);
    }

    // This will update account state if a user signs in from another tab or window
    instance.enableAccountStorageEvents();

    instance.handleRedirectPromise().then((response) => {
      if (response) {
        const { account } = response;
        if (account) {
          setAccount(response.account);
        }
      }
    });
  }, [instance]);

  return (
    <AuthContext.Provider
      value={{
        userHasLoggedIn,
        handleAzureAdLoginAsync,
        handleAzureAdLoginWithRedirect,
        acquireAccessTokenAsync,
        authenticationInProgress: inProgress !== InteractionStatus.None,
        account,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

/**
 * useAuth hook
 *
 * Hook for accessing the AuthContext. This provides a way to access
 * the Azure AD authentication functionality from any component.
 *
 * @return {AuthContextType}
 * Contains `azureAd`, which is an object of AzureAdAuthentication type that
 * provides user authentication related functionality.
 *
 * Example of azureAd usage:
 *
 * const { azureAd } = useAuth();
 *
 * // azureAd object includes:
 * - userHasLoggedIn(): Checks if the user is logged in.
 * - handleAzureAdLogin(): Handles the login process.
 * - acquireAccessToken(): Acquires the access token.
 * - account: Information about the logged-in account.
 *
 * Note: azureAd could be null, so always check or use optional chaining before accessing its properties or methods.
 *
 * Example:
 *
 * useEffect(() => {
 *   if (azureAd?.userHasLoggedIn()) {
 *     // do something
 *   }
 * }, [azureAd]);
 *
 */
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

import React from "react";
import { useTranslation } from "react-i18next";

import "./WeekHeader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import * as timeUtils from "../../utils/timeUtils";

interface WeekHeaderProps {
  selectedWeek: number;
  onPrevWeekClick: () => void;
  onNextWeekClick: () => void;
}

const WeekHeader: React.FC<WeekHeaderProps> = ({ selectedWeek, onPrevWeekClick, onNextWeekClick }) => {
  const { t } = useTranslation();

  let headerText = timeUtils.formatWeek(selectedWeek, t);

  return (
    <div className="weekHeader">
      <div className="weekNavigation">
        <button title="Go to previous week" type="button" onClick={onPrevWeekClick}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <div>
          <h2>{selectedWeek}</h2>
          <small>{headerText}</small>
        </div>
        <button
          title="Go to the next week"
          type="button"
          onClick={onNextWeekClick}
          className={timeUtils.isThisWeek(selectedWeek) ? "invisible" : ""}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};

export default WeekHeader;

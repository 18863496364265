import React, { createContext, useState, useContext, ReactNode } from "react";

interface Progress {
  [key: string]: boolean;
}

const ProgressContext = createContext<
  | {
      progress: Progress;
      setProgress: (name: string, isInProgress: boolean) => void;
      isInitializing: () => boolean;
      setInitializingUser: (isInitializing: boolean) => void;
      isInitializingUser: () => boolean;
      setInitializingUserActivities: (isInitializing: boolean) => void;
      isInitializingUserActivities: () => boolean;
    }
  | undefined
>(undefined);

interface ProgressContextProviderProps {
  children: ReactNode;
}

export const ProgressProvider: React.FC<ProgressContextProviderProps> = ({ children }) => {
  const [progress, setProgress] = useState<Progress>({});

  const setProgressStateWrapper = (name: string, isInProgress: boolean) => {
    setProgress((prevState) => ({ ...prevState, [name]: isInProgress }));
  };

  const isInitializingUser = () => {
    return progress["initializingUser"] === true;
  };

  const setInitializingUser = (isInitializing: boolean) => {
    setProgressStateWrapper("initializingUser", isInitializing);
  };

  const setInitializingUserActivities = (isInitializing: boolean) => {
    setProgressStateWrapper("initializingUserActivities", isInitializing);
  };

  const isInitializingUserActivities = () => {
    return progress["initializingUserActivities"] === true;
  };

  const isInitializing = () => {
    return Object.values(progress).some((value) => value === true);
  };

  return (
    <ProgressContext.Provider
      value={{
        progress,
        setProgress: setProgressStateWrapper,
        isInitializing,
        setInitializingUser,
        isInitializingUser,
        setInitializingUserActivities,
        isInitializingUserActivities,
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
};

export const useProgress = () => {
  const context = useContext(ProgressContext);
  if (!context) {
    throw new Error("useProgress must be used within a ProgressProvider");
  }
  return context;
};

import { apiUrls, urlTemplate } from "../apiConfig";
import { User } from "../../../common/model/user";
import { Activity } from "../../../common/model/activity";
import { WeeklySummary } from "../../../common/model/weeklySummary";
import { ExportFormatType } from "../../../common/model/exportFormatType";
import { TrendsDto } from "../../../common/model/trends";

const fetchWithAccessTokenAsync = async (
  url: string,
  accessToken: string,
  method: "GET" | "POST" | "DELETE" = "GET",
  body?: any
): Promise<Response> => {
  const options: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  if (method === "POST" && body) {
    options.body = JSON.stringify(body);
  }

  const response = await fetch(url, options);
  if (!response.ok) {
    let errMsg = "";
    try {
      const errorResponse = await response.json();
      errMsg = errorResponse.message || "";
    } catch (parseError) {
      errMsg = `An error occurred in API call to ${url} and the body of the response could not be parsed: ${parseError}`;
    }

    throw new Error(`${response.status}, ${errMsg}`);
  }

  return response;
};

export const ensureUserExistsAsync = async (
  acquireAccessToken: () => Promise<string>,
  newUser: User
): Promise<User> => {
  try {
    const accessToken = await acquireAccessToken();
    const response = await fetchWithAccessTokenAsync(apiUrls.createUser, accessToken, "POST", newUser);

    const createdUser: User = await response.json();
    return createdUser;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

export const getUserActivitiesAsync = async (acquireAccessToken: () => Promise<string>): Promise<Activity[]> => {
  try {
    const accessToken = await acquireAccessToken();
    const response = await fetchWithAccessTokenAsync(apiUrls.getUserActivities, accessToken);

    if (!response.ok) {
      throw new Error(`API error: ${response.status} ${response.statusText}`);
    }

    const activities: Activity[] = await response.json();
    return activities;
  } catch (error) {
    console.error("Error retrieving user's activities:", error);
    throw error;
  }
};

export const getUserWeeklySummaryAsync = async (
  acquireAccessToken: () => Promise<string>,
  weekNumber: number
): Promise<WeeklySummary> => {
  try {
    const accessToken = await acquireAccessToken();
    const url = urlTemplate.getUserWeeklySummary({ weekNumber });
    const response = await fetchWithAccessTokenAsync(url, accessToken);

    if (!response.ok) {
      throw new Error(`API error: ${response.status} ${response.statusText}`);
    }

    const weeklySummary: WeeklySummary = await response.json();
    return weeklySummary;
  } catch (error) {
    console.error("Error retrieving user weekly summary:", error);
    throw error;
  }
};

export const createActivityAsync = async (
  acquireAccessToken: () => Promise<string>,
  activity: Activity
): Promise<Activity> => {
  try {
    const accessToken = await acquireAccessToken();
    const response = await fetchWithAccessTokenAsync(apiUrls.createUserActivity, accessToken, "POST", activity);

    if (!response.ok) {
      throw new Error(`API error: ${response.status} ${response.statusText}`);
    }

    const createdActivity: Activity = await response.json();
    return createdActivity;
  } catch (error) {
    console.error(`Error during ${apiUrls.createUserActivity} API call: ${error}`);
    throw error;
  }
};

export const deleteActivityAsync = async (
  acquireAccessToken: () => Promise<string>,
  activityId: string
): Promise<void> => {
  const url = urlTemplate.deleteUserActivity({ activityId });

  try {
    const accessToken = await acquireAccessToken();
    const response = await fetchWithAccessTokenAsync(url, accessToken, "DELETE");

    if (!response.ok) {
      throw new Error(`API error: ${response.status} ${response.statusText}`);
    }
  } catch (error) {
    console.error(`Error during ${url} API call: ${error}`);
    throw error;
  }
};

export const exportUserDataAsync = async (
  acquireAccessToken: () => Promise<string>,
  format: ExportFormatType
): Promise<string> => {
  try {
    const accessToken = await acquireAccessToken();
    const url = new URL(apiUrls.exportUserData);
    url.searchParams.append("format", format);
    const response = await fetchWithAccessTokenAsync(url.toString(), accessToken);

    if (!response.ok) {
      throw new Error(`API error: ${response.status} ${response.statusText}`);
    }

    const userDataExport: string = await response.text();
    return userDataExport;
  } catch (error) {
    console.error("Error retrieving user data export:", error);
    throw error;
  }
};

export const exportAllDataAsync = async (
  acquireAccessToken: () => Promise<string>,
  format: ExportFormatType
): Promise<string> => {
  try {
    const accessToken = await acquireAccessToken();
    const url = new URL(apiUrls.exportAllData);
    url.searchParams.append("format", format);
    const response = await fetchWithAccessTokenAsync(url.toString(), accessToken);

    if (!response.ok) {
      throw new Error(`API error: ${response.status} ${response.statusText}`);
    }

    const userDataExport: string = await response.text();
    return userDataExport;
  } catch (error) {
    console.error("Error retrieving exported data:", error);
    throw error;
  }
};

export const getTrendsForPeriodsAsync = async (
  acquireAccessToken: () => Promise<string>,
  date?: Date,
  week?: number,
  month?: string,
  year?: number
): Promise<TrendsDto> => {
  try {
    const accessToken = await acquireAccessToken();
    const url = new URL(apiUrls.getTrends);
    if (date) {
      url.searchParams.append("date", date.toISOString());
    }
    if (week) {
      url.searchParams.append("week", week.toString());
    }
    if (month) {
      url.searchParams.append("month", month.toString());
    }
    if (year) {
      url.searchParams.append("year", year.toString());
    }
    const response = await fetchWithAccessTokenAsync(url.toString(), accessToken);

    if (!response.ok) {
      throw new Error(`API error: ${response.status} ${response.statusText}`);
    }

    const trends: TrendsDto = await response.json();
    return trends;
  } catch (error) {
    console.error("Error retrieving trends:", error);
    throw error;
  }
};

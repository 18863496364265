import { useTranslation } from "react-i18next";
import "./LoginSection.css";
import { useAuth } from "../../contexts/authContext";

function LoginSection() {
  const { t } = useTranslation();
  const { handleAzureAdLoginWithRedirect } = useAuth();

  const handleLogin = async () => {
    await handleAzureAdLoginWithRedirect();
  };

  return (
    <button type="submit" className="login-button" onClick={handleLogin}>
      {t("login")}
    </button>
  );
}

export default LoginSection;

import React, { useEffect, useRef, useState } from "react";
import UserActivityChart from "../common/UserActivityChart";
import { useTranslation } from "react-i18next";
import "./TrendsPage.css";
import { useTrends } from "../hooks/useTrends";
import * as timeUtils from "../../utils/timeUtils";
import { format, isToday } from "date-fns";
import ProgressSpinnerWithOverlay from "../common/ProgressSpinnerWithOverlay";
import { PeriodNavigation } from "../common/PeriodNavigation";
import { useUser } from "../../contexts/userContext";

const TrendsPage: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [date, setDate] = useState(new Date());
  const [week, setWeek] = useState(Number(timeUtils.getCurrentWeekNumber()));
  const [month, setMonth] = useState(timeUtils.getCurrentMonthAndYearConcatenated());
  const [year, setYear] = useState(timeUtils.getCurrentYear());
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef(false);

  const {
    trends,
    updateTrendsForPeriodsAsync,
    updateTrendsForDateAsync,
    updateTrendsForWeekAsync,
    updateTrendsForMonthAsync,
    updateTrendsForYearAsync,
  } = useTrends();

  useEffect(() => {
    if (!trends) {
      handleAsyncFetch(async () => {
        updateTrendsForPeriodsAsync(date, week, month, year);
      });
    }
  }, [trends]);

  async function handleAsyncFetch(func: () => Promise<void>): Promise<void> {
    if (isLoadingRef.current) return;
    isLoadingRef.current = true;

    setIsLoading(true);
    try {
      await func();
    } catch (err) {
      console.error(err); // handle error as appropriate
    } finally {
      isLoadingRef.current = false;
      setIsLoading(false);
    }
  }

  function onPrevDayClick(): void {
    handleAsyncFetch(async () => {
      const prevDate = new Date(date);
      prevDate.setDate(prevDate.getDate() - 1);
      setDate(prevDate);
      await updateTrendsForDateAsync(prevDate);
    });
  }

  function onNextDayClick(): void {
    handleAsyncFetch(async () => {
      const nextDate = new Date(date);
      nextDate.setDate(nextDate.getDate() + 1);
      setDate(nextDate);
      await updateTrendsForDateAsync(nextDate);
    });
  }

  function onPrevWeekClick(): void {
    handleAsyncFetch(async () => {
      const prevWeek = week - 1;
      setWeek(prevWeek);
      await updateTrendsForWeekAsync(prevWeek);
    });
  }

  function onNextWeekClick(): void {
    handleAsyncFetch(async () => {
      const nextWeek = week + 1;
      setWeek(nextWeek);
      await updateTrendsForWeekAsync(nextWeek);
    });
  }

  function onPrevMonthClick(): void {
    handleAsyncFetch(async () => {
      let [yearStr, monthStr] = month.split("-");
      let yearNum = parseInt(yearStr);
      let monthNum = parseInt(monthStr);

      monthNum -= 1;
      if (monthNum < 1) {
        monthNum = 12;
        yearNum -= 1;
      }

      const prevMonth = `${yearNum}-${String(monthNum).padStart(2, "0")}`;
      setMonth(prevMonth);
      await updateTrendsForMonthAsync(prevMonth);
    });
  }

  function onNextMonthClick(): void {
    handleAsyncFetch(async () => {
      let [yearStr, monthStr] = month.split("-");
      let yearNum = parseInt(yearStr);
      let monthNum = parseInt(monthStr);

      monthNum += 1;
      if (monthNum > 12) {
        monthNum = 1;
        yearNum += 1;
      }

      const nextMonth = `${yearNum}-${String(monthNum).padStart(2, "0")}`;
      setMonth(nextMonth);
      await updateTrendsForMonthAsync(nextMonth);
    });
  }

  function onPrevYearClick(): void {
    handleAsyncFetch(async () => {
      const prevYear = year - 1;
      setYear(prevYear);
      await updateTrendsForYearAsync(prevYear);
    });
  }

  function onNextYearClick(): void {
    handleAsyncFetch(async () => {
      const nextYear = year + 1;
      setYear(nextYear);
      await updateTrendsForYearAsync(nextYear);
    });
  }

  let dateText = isToday(date) ? t("Today") : format(date, "dd.MM.yy");
  let weekText = timeUtils.isThisWeek(week) ? t("This week") : timeUtils.formatWeek(week, t);
  let monthText = timeUtils.isThisMonth(month) ? t("This month") : month;
  let yearText = timeUtils.isThisYear(year) ? t("This year") : year;
  let currentUsersFirstName = user?.name.split(" ")[0];

  return (
    <div className="trendsPage">
      {isLoading && <ProgressSpinnerWithOverlay />}

      {trends?.date && (
        <div className="section">
          <h2>{dateText}</h2>
          <UserActivityChart trendPeriod={trends.date} highlightedUser={currentUsersFirstName} />
          <PeriodNavigation
            isLoading={isLoading}
            onPrevClick={onPrevDayClick}
            onNextClick={onNextDayClick}
            isCurrentPeriod={isToday(date)}
          />
        </div>
      )}

      {trends?.week && (
        <div className="section">
          <h2>{weekText}</h2>
          <UserActivityChart trendPeriod={trends.week} highlightedUser={currentUsersFirstName} />
          <PeriodNavigation
            isLoading={isLoading}
            onPrevClick={onPrevWeekClick}
            onNextClick={onNextWeekClick}
            isCurrentPeriod={timeUtils.isThisWeek(week)}
          />
        </div>
      )}

      {trends?.month && (
        <div className="section">
          <h2>{monthText}</h2>
          <UserActivityChart trendPeriod={trends.month} highlightedUser={currentUsersFirstName} />
          <PeriodNavigation
            isLoading={isLoading}
            onPrevClick={onPrevMonthClick}
            onNextClick={onNextMonthClick}
            isCurrentPeriod={timeUtils.isThisMonth(month)}
          />
        </div>
      )}

      {trends?.year && (
        <div className="section">
          <h2>{yearText}</h2>
          <UserActivityChart trendPeriod={trends.year} highlightedUser={currentUsersFirstName} />
          <PeriodNavigation
            isLoading={isLoading}
            onPrevClick={onPrevYearClick}
            onNextClick={onNextYearClick}
            isCurrentPeriod={timeUtils.isThisYear(year)}
          />
        </div>
      )}
    </div>
  );
};

export default TrendsPage;

import { BrowserRouter as Router } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./msalConfig";
import "./App.css";
import Main from "./Main";
import { AuthProvider } from "./contexts/authContext";
import { CalendarProvider } from "./contexts/calendarContext";
import { UserProvider } from "./contexts/userContext";
import { ActivityProvider } from "./contexts/activityContext";
import { ProgressProvider } from "./contexts/progressContext";
import { MsalProvider } from "@azure/msal-react";

const pca = new PublicClientApplication(msalConfig);

const App = () => {
  return (
    <div className="App">
      <Router>
        <MsalProvider instance={pca}>
          <AuthProvider>
            <ProgressProvider>
              <CalendarProvider>
                <UserProvider>
                  <ActivityProvider>
                    <Main />
                  </ActivityProvider>
                </UserProvider>
              </CalendarProvider>
            </ProgressProvider>
          </AuthProvider>
        </MsalProvider>
      </Router>
    </div>
  );
};

export default App;

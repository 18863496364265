import { useTranslation } from "react-i18next";

import "./TimeButtonGroup.css";

const TimeButtonGroup: React.FC<{ onButtonClick: (minutes: number) => void }> = ({ onButtonClick }) => {
  const { t } = useTranslation();

  return (
    <div className="button-group">
      {Array.from({ length: 4 }).map((_, i) => (
        <button className="time-button" key={i} onClick={() => onButtonClick((i + 1) * 15)}>
          +{(i + 1) * 15} {t("minutes")}
        </button>
      ))}
    </div>
  );
};

export default TimeButtonGroup;

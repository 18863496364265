import LoginSection from "../sections/LoginSection";
import AuthenticationSection from "../sections/AuthenticatingSection";
import { useNavigate } from "react-router-dom";
import { WEEK_PAGE } from "../../routes";
import { APP_NAME } from "../../constants";
import { useAuth } from "../../contexts/authContext";
import { useEffect } from "react";
import "./HomePage.css";

// Route: /
function HomePage() {
  const navigate = useNavigate();
  const { userHasLoggedIn, authenticationInProgress } = useAuth();

  useEffect(() => {
    if (userHasLoggedIn()) {
      navigate(WEEK_PAGE);
    }
  }, [userHasLoggedIn, navigate]);

  return (
    <div className="homePage">
      <header className="homePage-header">
        <h1>{APP_NAME}</h1>
        {authenticationInProgress ? <AuthenticationSection /> : <LoginSection />}
      </header>
    </div>
  );
}

export default HomePage;

import { useState, useCallback } from "react";
import { TrendPeriod, Trends } from "../../domain/Trends";

import * as apiClient from "../../api/apiClient";
import { useAuth } from "../../contexts/authContext";

export function useTrends() {
  const { acquireAccessTokenAsync } = useAuth();
  const [trends, setTrends] = useState<Trends | null>(null);

  const updateTrendsForDateAsync = useCallback(async (date: Date) => {
    const response = await apiClient.getTrendsForPeriodsAsync(acquireAccessTokenAsync, date);
    if (response.date) {
      const newTrendPeriodForDate = TrendPeriod.fromDto(response.date);
      setTrends((prevTrends) => ({
        ...prevTrends,
        date: newTrendPeriodForDate,
      }));
    }
  }, []);

  const updateTrendsForWeekAsync = useCallback(async (week: number) => {
    const response = await apiClient.getTrendsForPeriodsAsync(acquireAccessTokenAsync, undefined, week);
    if (response.week) {
      const newTrendPeriodForWeek = TrendPeriod.fromDto(response.week);
      setTrends((prevTrends) => ({
        ...prevTrends,
        week: newTrendPeriodForWeek,
      }));
    }
  }, []);

  const updateTrendsForMonthAsync = useCallback(async (month: string) => {
    const response = await apiClient.getTrendsForPeriodsAsync(acquireAccessTokenAsync, undefined, undefined, month);
    if (response.month) {
      const newTrendPeriodForMonth = TrendPeriod.fromDto(response.month);
      setTrends((prevTrends) => ({
        ...prevTrends,
        month: newTrendPeriodForMonth,
      }));
    }
  }, []);

  const updateTrendsForYearAsync = useCallback(async (year: number) => {
    const response = await apiClient.getTrendsForPeriodsAsync(
      acquireAccessTokenAsync,
      undefined,
      undefined,
      undefined,
      year
    );
    if (response.year) {
      const newTrendPeriodForYear = TrendPeriod.fromDto(response.year);
      setTrends((prevTrends) => ({
        ...prevTrends,
        year: newTrendPeriodForYear,
      }));
    }
  }, []);

  const updateTrendsForPeriodsAsync = useCallback(async (date: Date, week: number, month: string, year: number) => {
    const response = await apiClient.getTrendsForPeriodsAsync(acquireAccessTokenAsync, date, week, month, year);
    setTrends(Trends.fromDto(response));
  }, []);

  return {
    trends,
    updateTrendsForPeriodsAsync,
    updateTrendsForDateAsync,
    updateTrendsForWeekAsync,
    updateTrendsForMonthAsync,
    updateTrendsForYearAsync,
  };
}

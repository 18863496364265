import React from "react";
import { useTranslation } from "react-i18next";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from "recharts";
import { PeriodType, TrendPeriod } from "../../domain/Trends";

import "./UserActivityChart.css";

interface UserActivityChartProps {
  trendPeriod: TrendPeriod;
  highlightedUser?: string;
}

const convertTime = (type: PeriodType, minutes: number): number => {
  switch (type) {
    case "DATE":
    case "WEEK":
      return minutes; // return minutes for day and week
    case "MONTH":
    case "YEAR":
      return minutes / 60; // convert minutes to hours for month and year
  }
};

const UserActivityChart: React.FC<UserActivityChartProps> = ({ trendPeriod, highlightedUser }) => {
  const { t } = useTranslation();

  const transformedData = trendPeriod.users.map(({ displayName, readingMinutes, movementMinutes }) => ({
    name: displayName.split(" ")[0],
    [t("Reading")]: convertTime(trendPeriod.period.type, readingMinutes),
    [t("Movement")]: convertTime(trendPeriod.period.type, movementMinutes),
  }));

  const localizedTimeUnit = t(
    trendPeriod.period.type === "YEAR" || trendPeriod.period.type === "MONTH" ? "hours" : "minutes"
  );

  // If no data is available, return a message saying so
  if (transformedData.length === 0) {
    return (
      <div className="chart-placeholder">
        <p>{t("No data available")}</p>
      </div>
    );
  }

  const renderReadingBar = (props: any) => {
    const { x, y, width, height } = props;
    const isHighlighted = props.name === highlightedUser;

    const className = isHighlighted ? "highlighted" : "notHighlighted";

    return <rect x={x} y={y} width={width} height={height} fill="#7e57c2" className={className} />;
  };

  const renderMovementBar = (props: any) => {
    const { x, y, width, height } = props;
    const isHighlighted = props.name === highlightedUser;

    const className = isHighlighted ? "highlighted" : "notHighlighted";

    return <rect x={x} y={y} width={width} height={height} fill="#ff7043" className={className} />;
  };

  return (
    <div className="chartContainer">
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={transformedData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis label={{ value: localizedTimeUnit, angle: -90, position: "insideLeft" }} />
          <Tooltip />
          <Legend />
          <Bar dataKey={t("Reading", "Reading") as string} fill="#7e57c2" shape={renderReadingBar} />
          <Bar dataKey={t("Movement", "Movement") as string} fill="#ff7043" shape={renderMovementBar} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default UserActivityChart;

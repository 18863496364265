import { ReactNode, createContext, useContext, useState } from "react";

import * as timeUtils from "../utils/timeUtils";

interface CalendarContextType {
  selectedWeek: number;
  setSelectedWeek: (week: number) => void;
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
}

export const CalendarContext = createContext<CalendarContextType>({
  selectedWeek: 0,
  setSelectedWeek: () => {},
  selectedDate: new Date(),
  setSelectedDate: () => {},
});

/**
 * @typedef CalendarContextProviderProps
 * @property {ReactNode} children - React components that will be rendered as children of the CalendarContextProviderProps.
 */
interface CalendarContextProviderProps {
  children: ReactNode;
}

export const CalendarProvider: React.FC<CalendarContextProviderProps> = ({ children }) => {
  const [selectedWeek, setSelectedWeek] = useState(timeUtils.getCurrentWeekNumber());
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  return (
    <CalendarContext.Provider
      value={{
        selectedWeek,
        setSelectedWeek,
        selectedDate,
        setSelectedDate,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

export const useCalendar = (): CalendarContextType => {
  const context = useContext(CalendarContext);
  if (context === undefined) {
    throw new Error("useCalendar must be used within a CalendarProvider");
  }
  return context;
};

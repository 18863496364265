import "./AllActivitiesPage.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useActivity } from "../../contexts/activityContext";

const AllActivitiesPage: React.FC = () => {
  const { t } = useTranslation();
  const { activityTracker, deleteActivityAsync } = useActivity();

  if (!activityTracker) {
    return null;
  }

  const activitiesByWeek = activityTracker.getAllActivitiesByWeekInDescOrder();

  const handleDelete = async (activityId: string) => {
    await deleteActivityAsync(activityId);
  };

  return (
    <div className="AllActivitiesPage">
      {activitiesByWeek.map((week, index) => (
        <div key={index}>
          <h2 className="weekHeader">{`${t("Week")} ${week.weekNumber}`}</h2>
          <div className="activitiesSection">
            {week.activities.map((activity, activityIndex) => (
              <div key={activityIndex} className={`activityCard ${activity.type.toLowerCase()}`}>
                <div className="cardHeader">
                  <h3>{t(activity.type)}</h3>
                  <button
                    className="deleteBtn"
                    onClick={() => handleDelete(activity.activityId!)}
                    aria-label="Delete activity"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} className="trashIcon" />
                  </button>
                </div>
                <p>
                  {activity.duration} {t("minutes")}
                </p>
                <p>{activity.datetime.toLocaleDateString()}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllActivitiesPage;

import { Route, Routes, useLocation } from "react-router-dom";
import WeekPage from "./components/pages/WeekPage";
import * as routes from "./routes";
import LoginCallbackPage from "./components/pages/LoginCallbackPage";
import NotFoundPage from "./components/pages/NotFoundPage";
import HomePage from "./components/pages/HomePage";
import DayPage from "./components/pages/DayPage";
import ProfilePage from "./components/pages/ProfilePage";
import Navigation from "./components/common/Navigation";
import AllActivitiesPage from "./components/pages/AllActivitiesPage";
import { useAuth } from "./contexts/authContext";
import { useEffect } from "react";
import { useUser } from "./contexts/userContext";
import { useActivity } from "./contexts/activityContext";
import { WithAuthAndProgress } from "./components/hoc/withAuthAndProgress";
import AddActivityPage from "./components/pages/AddActivityPage";
import TrendsPage from "./components/pages/TrendsPage";

const Main = () => {
  const location = useLocation();
  const { userHasLoggedIn } = useAuth();
  const { user, initializeUserAsync } = useUser();
  const { initializeActivityTrackerAsync } = useActivity();

  // TODO this should NOT be forever retried

  useEffect(() => {
    if (userHasLoggedIn() && user === null) {
      initializeUserAsync();
    }
  }, [userHasLoggedIn, initializeUserAsync, user]);

  useEffect(() => {
    if (user) {
      initializeActivityTrackerAsync(user);
    }
  }, [user, initializeActivityTrackerAsync]);

  return (
    <>
      {location.pathname !== routes.HOME_PAGE &&
        location.pathname !== routes.LOGIN_CALLBACK_PAGE &&
        location.pathname !== routes.NOT_FOUND_PAGE && <Navigation />}
      <Routes>
        <Route path={routes.WEEK_PAGE} element={<WithAuthAndProgress component={WeekPage} />} />
        <Route path={routes.LOGIN_CALLBACK_PAGE} element={<LoginCallbackPage />} />
        <Route path={routes.PROFILE_PAGE} element={<WithAuthAndProgress component={ProfilePage} />} />
        <Route path={routes.DAY_PAGE} element={<WithAuthAndProgress component={DayPage} />} />
        <Route path={routes.ADD_ACTIVITY_PAGE} element={<WithAuthAndProgress component={AddActivityPage} />} />
        <Route path={routes.ALL_ACTIVITIES_PAGE} element={<WithAuthAndProgress component={AllActivitiesPage} />} />
        <Route path={routes.TRENDS_PAGE} element={<WithAuthAndProgress component={TrendsPage} />} />
        <Route path={routes.NOT_FOUND_PAGE} element={<NotFoundPage />} />
        <Route path={routes.HOME_PAGE} element={<HomePage />} />
      </Routes>
    </>
  );
};

export default Main;

import { useTranslation } from "react-i18next";
import User from "../../domain/User";
import "./ProfilePage.css";
import { useUser } from "../../contexts/userContext";
import { ExportFormatType } from "../../../../common/model/exportFormatType";

interface UserProfileProps {
  user: User;
}

const UserProfile = ({ user }: UserProfileProps) => {
  const { t } = useTranslation();

  return (
    <div className="section">
      <h2 className="lively-header">{t("UserProfile")}</h2>
      <p>
        <strong>{t("Name")}:</strong> {user.name}
      </p>
      <p>
        <strong>{t("Email")}:</strong> {user.email}
      </p>
      <p>
        <strong>{t("UserName")}:</strong> {user.userName}
      </p>
    </div>
  );
};

interface ExportProps {
  exportUserDataAsync: (format: ExportFormatType) => Promise<void>;
  exportAllDataAysnc: (format: ExportFormatType) => Promise<void>;
}

const Export = (props: ExportProps) => {
  const { t } = useTranslation();

  const exportUserData = async (format: ExportFormatType) => {
    await props.exportUserDataAsync(format);
  };

  const exportAllData = async (format: ExportFormatType) => {
    await props.exportAllDataAysnc(format);
  };

  return (
    <div className="section">
      <h2 className="lively-header">{t("Actions")}</h2>
      <button onClick={() => exportUserData("csv")} className="exportButton">
        {t("Export user data as CSV")}
      </button>
      <button onClick={() => exportUserData("json")} className="exportButton">
        {t("Export user data as JSON")}
      </button>
      <button onClick={() => exportAllData("csv")} className="exportButton">
        {t("Export all data as CSV")}
      </button>
      <button onClick={() => exportAllData("json")} className="exportButton">
        {t("Export all data as JSON")}
      </button>
    </div>
  );
};

const ProfilePage: React.FC = () => {
  const { user, exportUserDataAsync, exportAllDataAsync } = useUser();

  if (!user) return null;

  return (
    <div className="profilePage">
      <UserProfile user={user} />
      <Export exportUserDataAsync={exportUserDataAsync} exportAllDataAysnc={exportAllDataAsync} />
    </div>
  );
};

export default ProfilePage;

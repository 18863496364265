import { Activity } from "./Activity";
import User from "./User";
import Week from "./Week";

import * as timeUtils from "../utils/timeUtils";
import Day from "./Day";

class ActivityTracker {
  constructor(public user: User, public activities: Activity[] = []) {
    this.activities.sort((a, b) => b.datetime.getTime() - a.datetime.getTime());
  }

  deleteActivity(activityId: string): ActivityTracker {
    return new ActivityTracker(
      this.user,
      this.activities.filter((activity) => activity.activityId !== activityId)
    );
  }

  getAllActivitiesByWeekInDescOrder(): Week[] {
    const allActivities = this.activities;

    // Create a unique identifier for each activity as 'year-weekNumber'
    const yearWeekIdentifiers = allActivities.map((activity) => {
      const year = activity.getYear();
      const weekNumber = activity.weekNumber;
      return `${year}-${weekNumber}`;
    });

    // Get unique identifiers
    const uniqueIdentifiers = [...new Set(yearWeekIdentifiers)];

    // Sort identifiers in descending order by year, then by week number
    uniqueIdentifiers.sort((a, b) => {
      const [yearA, weekA] = a.split("-").map(Number);
      const [yearB, weekB] = b.split("-").map(Number);
      if (yearB !== yearA) {
        return yearB - yearA;
      }
      return weekB - weekA;
    });

    // Map the sorted identifiers to Week objects
    return uniqueIdentifiers.map((identifier) => {
      const [year, weekNumber] = identifier.split("-").map(Number);
      return this.getWeek(year, weekNumber);
    });
  }

  getDay(date: Date): Day {
    return new Day(date, this);
  }

  getWeek(yearNumber: number, weekNumber: number): Week {
    return new Week(yearNumber, weekNumber, this);
  }

  addActivity(activity: Activity): ActivityTracker {
    return new ActivityTracker(this.user, [...this.activities, activity]);
  }

  addActivities(activities: Activity[]): ActivityTracker {
    return new ActivityTracker(this.user, [...this.activities, ...activities]);
  }

  getActivitiesForWeek(fullYear: number, weekNumber: number): Activity[] {
    return this.activities.filter((activity) => activity.getYear() === fullYear && activity.weekNumber === weekNumber);
  }

  getActivitiesForDay(date: Date): Activity[] {
    return this.activities.filter((activity) => {
      const activityDate = new Date(activity.datetime);
      return (
        activityDate.getDate() === date.getDate() &&
        activityDate.getMonth() === date.getMonth() &&
        activityDate.getFullYear() === date.getFullYear()
      );
    });
  }

  getExerciseTimeThisWeek() {
    const thisWeekActivities = this.getActivitiesForWeek(new Date().getFullYear(), timeUtils.getCurrentWeekNumber());
    return this.getTotalTimeForActivitiesOfType(thisWeekActivities, "MOVEMENT");
  }

  getReadingTimeThisWeek() {
    const thisWeekActivities = this.getActivitiesForWeek(new Date().getFullYear(), timeUtils.getCurrentWeekNumber());
    return this.getTotalTimeForActivitiesOfType(thisWeekActivities, "READING");
  }

  getExerciseTimeToday() {
    const todayActivities = this.getActivitiesForDay(timeUtils.getCurrentDate());
    return this.getTotalTimeForActivitiesOfType(todayActivities, "MOVEMENT");
  }

  getReadingTimeToday() {
    const todayActivities = this.getActivitiesForDay(timeUtils.getCurrentDate());
    return this.getTotalTimeForActivitiesOfType(todayActivities, "READING");
  }

  getReadingDataThisWeek(): any[] {
    return this.getDataForActivityThisWeek("READING");
  }

  getExerciseDataThisWeek(): any[] {
    return this.getDataForActivityThisWeek("MOVEMENT");
  }

  private getDataForActivityThisWeek(activityName: string): any[] {
    const allActivitiesOfThisWeek = this.getActivitiesForWeek(
      new Date().getFullYear(),
      timeUtils.getCurrentWeekNumber()
    );
    const allActivitiesOfThisWeekFilteredByName = allActivitiesOfThisWeek.filter(
      (activity) => activity.type === activityName
    );

    const daysOfWeek = timeUtils.getWeekdays();

    return daysOfWeek.map((day, index) => {
      const activitiesForDay = allActivitiesOfThisWeekFilteredByName.filter(
        (activity) => activity.getWeekDayNumber() === index
      );
      const totalDuration = activitiesForDay.reduce((total, activity) => total + activity.duration, 0);
      return {
        name: day,
        minutes: totalDuration,
      };
    });
  }

  private getTotalTimeForActivitiesOfType(activities: Activity[], activityName: string): number {
    return activities
      .filter((activity) => activity.type === activityName)
      .reduce((total, activity) => total + activity.duration, 0);
  }
}

export default ActivityTracker;

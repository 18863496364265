import { useTranslation } from "react-i18next";
import "./AuthenticatingSection.css";

const AuthenticatingSection = () => {
  const { t } = useTranslation();

  return (
    <div className="Authenticating">
      <h3>{t("Authenticating")}</h3>
    </div>
  );
};
export default AuthenticatingSection;

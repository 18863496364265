export const apiScopes = ["api://api.pagepump.laliadtuka.eu/user_impersonation"];

const apiUrlBase = process.env.REACT_APP_API_URL;

if (!apiUrlBase) {
  throw new Error("REACT_APP_API_URL environment variable is not set");
}

export const apiUrls = {
  createUser: `${apiUrlBase}/user`,
  updateUser: `${apiUrlBase}/user`,
  getUser: `${apiUrlBase}/user`,
  createUserActivity: `${apiUrlBase}/user/activity`,
  getUserActivity: `${apiUrlBase}/user/activity/{activityId}`,
  updateUserActivity: `${apiUrlBase}/user/activity/{activityId}`,
  deleteUserActivity: `${apiUrlBase}/user/activity/{activityId}`,
  getUserActivities: `${apiUrlBase}/user/activities`,
  getUserWeeklySummary: `${apiUrlBase}/user/activities/week/{weekNumber}`,
  exportUserData: `${apiUrlBase}/user/export`,
  exportAllData: `${apiUrlBase}/export`,
  getTrends: `${apiUrlBase}/trends`,
  // Add other API URLs here as needed
};

// Type definition:
// We define a type alias `UrlTemplate` for an object. The keys of this object
// are the same as those in `apiUrls` and the values are functions that take
// an object (with string keys and values), and return a string.
// Here, [K in keyof typeof apiUrls] creates a union of keys present in apiUrls.
// (params: Record<string, string>) => string represents a function that takes an object and returns a string.
export type UrlTemplate = {
  [K in keyof typeof apiUrls]: (params: Record<string, any>) => string;
};

// Proxy creation:
// The Proxy object is used to define custom behavior for fundamental
// operations (e.g., property lookup, assignment, enumeration, function invocation, etc).
// The first parameter to the Proxy constructor represents the object which the
// proxy virtualizes or upon which the proxy is based.
// Here, an empty object {} is used as the base which means we're not forwarding
// any operations to a particular object. Instead, we're creating an entirely
// new behavior when a property is accessed.
export const urlTemplate = new Proxy(
  {},
  {
    // Proxy handler:
    // The second parameter to the Proxy constructor is an object whose properties
    // are functions which define the behavior of the proxy when various operations
    // are performed on it.
    // 'get' is one of the handler's methods, triggered when a property is accessed.
    get: function (_, property: keyof typeof apiUrls) {
      // The 'get' method returns a function that takes an object of parameters.
      // This function replaces each placeholder in the URL from `apiUrls` with
      // the corresponding value from the parameters object.
      // The function ultimately returns the final URL with all placeholders replaced.
      return function (params: Record<string, any>) {
        return Object.keys(params).reduce(
          // Here, the 'reduce' method is used to replace placeholders in the
          // URL with actual values. It executes a function on each of these keys.
          // This function replaces the '{key}' placeholder in the current 'url'
          // with the corresponding value from the 'params' object, and returns the result.
          (url, key) => url.replace(`{${key}}`, params[key]),
          // The 'reduce' operation starts with the original URL from `apiUrls`.
          // This is the string that will have its placeholders replaced.
          apiUrls[property]
        );
      };
    },
  }
) as UrlTemplate; // Finally, we assert that the Proxy has the type `UrlTemplate`.
// This tells TypeScript that the Proxy can be used as an
// object of type `UrlTemplate`.

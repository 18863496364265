import React from "react";
import { format, isToday, isYesterday } from "date-fns";
import { useTranslation } from "react-i18next";

import "./DateHeader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

interface DateHeaderProps {
  selectedDay: Date;
  onPrevDayClick: () => void;
  onNextDayClick: () => void;
}

const DateHeader: React.FC<DateHeaderProps> = ({ selectedDay, onPrevDayClick, onNextDayClick }) => {
  const { t } = useTranslation();

  let headerText = "";

  if (isToday(selectedDay)) {
    headerText = t("Today");
  } else if (isYesterday(selectedDay)) {
    headerText = t("Yesterday");
  } else {
    headerText = format(selectedDay, "dd.MM.yy");
  }

  return (
    <div className="dayHeader">
      <div className="dayNavigation">
        <button title="Go to previous day" type="button" onClick={onPrevDayClick}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <div>
          <h2>{headerText}</h2>
        </div>
        <button
          title="Go to the next day"
          type="button"
          onClick={onNextDayClick}
          className={isToday(selectedDay) ? "invisible" : ""}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};

export default DateHeader;

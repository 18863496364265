import React from "react";
import "./DateAndTimePicker.css";
import { useTranslation } from "react-i18next";

interface DateAndTimePickerProps {
  selectedDate: string;
  handleDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DateAndTimePicker: React.FC<DateAndTimePickerProps> = ({
  selectedDate,
  handleDateChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className="dateAndTimePicker">
      <label htmlFor="date-picker">{t("Select Date")}</label>
      <input
        type="date"
        id="date-picker"
        value={selectedDate}
        onChange={handleDateChange}
      />
    </div>
  );
};

export default DateAndTimePicker;

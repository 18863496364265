import React, { useState, useCallback } from "react";
import { useActivity } from "../../contexts/activityContext";
import { useTranslation } from "react-i18next"; // or your chosen library
import "./AddActivityPage.css";

import ActivityDurationInput from "../common/ActivityDurationInput";
import ActivityButton from "../common/ActivityButton";
import SuccessMessage from "../common/SuccessMessage";
import DateAndTimePicker from "../common/DateAndTimePicker";

interface FormValues {
  activityDuration: number | "";
}

interface Errors {
  activityDuration?: string;
}

const AddActivityPage: React.FC = () => {
  const { t } = useTranslation();
  const { addReadingTimeAsync, addExerciseTimeAsync } = useActivity();
  const [formValues, setFormValues] = useState<FormValues>({ activityDuration: 15 });
  const [errors, setErrors] = useState<Errors>({});
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedDate, setSelectedDate] = useState(() => new Date().toISOString().substr(0, 10));

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);
  };

  const convertInputToValidNumber = (input: string | number) => {
    let num = Number(input);
    return num < 15 ? 15 : num;
  };

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === "" ? "" : Number(e.target.value);
    setFormValues({
      activityDuration: value,
    });
  }, []);

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      let newValue = convertInputToValidNumber(formValues.activityDuration);
      setFormValues({ activityDuration: newValue });

      if (!Number.isInteger(newValue) || newValue < 15) {
        setErrors({
          activityDuration: t("mustBePositiveIntegerAndAtLeast15") || "Must be a positive integer and at least 15",
        });
      } else {
        setErrors({});
      }
    },
    [t, formValues.activityDuration]
  );

  const showSuccess = () => {
    setIsSuccessVisible(true);
    setTimeout(() => setIsSuccessVisible(false), 2000); // hide after 2 seconds
  };

  const validateDuration = useCallback(
    (duration: number) => {
      if (duration >= 15 && Number.isInteger(duration)) {
        setErrors({});
        return true;
      } else {
        setErrors({
          activityDuration: t("mustBePositiveIntegerAndAtLeast15") || "Must be a positive integer and at least 15",
        });
        return false;
      }
    },
    [t]
  );

  const handleActivityAddition = useCallback(
    async (action: (duration: number, date?: Date) => Promise<any>) => {
      try {
        setIsAdding(true);
        await action(Number(formValues.activityDuration), new Date(selectedDate));
        showSuccess(); // show success message when activity is added successfully
        setIsAdding(false);
      } catch (error) {
        setIsAdding(false);
        // handle any errors here
      }
    },
    [formValues.activityDuration, selectedDate]
  );

  const validateAndHandle = useCallback(
    (action: (duration: number) => Promise<any>) => {
      if (validateDuration(Number(formValues.activityDuration))) {
        handleActivityAddition(action);
      }
    },
    [formValues.activityDuration, validateDuration, handleActivityAddition]
  );

  const addReading = useCallback(() => {
    validateAndHandle(addReadingTimeAsync);
  }, [validateAndHandle, addReadingTimeAsync]);

  const addMovement = useCallback(() => {
    validateAndHandle(addExerciseTimeAsync);
  }, [validateAndHandle, addExerciseTimeAsync]);

  return (
    <div className="AddActivityPage">
      <SuccessMessage isVisible={isSuccessVisible} />
      <div className="section">
        <h2 className="lively-header">{t("Activity Information") || "Activity information"}</h2>
        <DateAndTimePicker selectedDate={selectedDate} handleDateChange={handleDateChange} />
        <ActivityDurationInput
          value={formValues.activityDuration}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors["activityDuration"]}
        />
      </div>
      <div className="activityTypeGroup section">
        <h2 className="lively-header">{t("Actions")}</h2>
        <ActivityButton onClick={addReading} disabled={isAdding} label="AddReading" buttonType="reading" />
        <ActivityButton onClick={addMovement} disabled={isAdding} label="AddMovement" buttonType="exercise" />
      </div>
    </div>
  );
};

export default AddActivityPage;

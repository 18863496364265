import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "./Navigation.css";
import * as routes from "../../routes";
import { useState } from "react";

const Navigation = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const closeMenu = () => {
    setIsOpen(false);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const getActivePage = () => {
    switch (location.pathname) {
      case routes.WEEK_PAGE:
        return t("WeekPage");
      case routes.DAY_PAGE:
        return t("DayPage");
      case routes.ALL_ACTIVITIES_PAGE:
        return t("All activities");
      case routes.TRENDS_PAGE:
        return t("Trends");
      case routes.ADD_ACTIVITY_PAGE:
        return t("Add activity");
      default:
        return t("Profile");
    }
  };

  return (
    <nav className="navigation">
      {!isOpen && (
        <button title="Open menu" onClick={toggleMenu} className="menu-icon">
          <FontAwesomeIcon icon={faBars} size="lg" />
        </button>
      )}

      {!isOpen && <span className={`current-page ${isOpen ? "" : "hide-on-large"}`}>{getActivePage()}</span>}

      <div className={`menu ${isOpen ? "show" : ""}`}>
        <NavLink
          onClick={closeMenu}
          to={routes.WEEK_PAGE}
          className={({ isActive }) => (isActive ? "activeRoute" : "")}
        >
          {t("WeekPage")}
        </NavLink>
        <NavLink onClick={closeMenu} to={routes.DAY_PAGE} className={({ isActive }) => (isActive ? "activeRoute" : "")}>
          {t("DayPage")}
        </NavLink>
        <NavLink
          onClick={closeMenu}
          to={routes.ALL_ACTIVITIES_PAGE}
          className={({ isActive }) => (isActive ? "activeRoute" : "")}
        >
          {t("All activities")}
        </NavLink>
        <NavLink
          onClick={closeMenu}
          to={routes.ADD_ACTIVITY_PAGE}
          className={({ isActive }) => (isActive ? "activeRoute" : "")}
        >
          {t("Add activity")}
        </NavLink>
        <NavLink
          onClick={closeMenu}
          to={routes.TRENDS_PAGE}
          className={({ isActive }) => (isActive ? "activeRoute" : "")}
        >
          {t("Trends")}
        </NavLink>
        <NavLink
          onClick={closeMenu}
          to={routes.PROFILE_PAGE}
          className={({ isActive }) => (isActive ? "activeRoute" : "")}
        >
          {t("Profile")}
        </NavLink>
      </div>
    </nav>
  );
};

export default Navigation;

import { Activity, Reading, Movement } from "./Activity";
import ActivityTracker from "./ActivityTracker";

class Week {
  public readingMinutes: number = 0;
  public exerciseMinutes: number = 0;
  public activities: Activity[] = [];

  constructor(public yearNumber: number, public weekNumber: number, public activityTracker: ActivityTracker) {
    this.loadActivities();
  }

  loadActivities(): void {
    this.activities = this.activityTracker.getActivitiesForWeek(this.yearNumber, this.weekNumber);
    this.activities.forEach((activity) => {
      if (activity instanceof Reading) this.readingMinutes += activity.duration;
      if (activity instanceof Movement) this.exerciseMinutes += activity.duration;
    });
  }
}

export default Week;

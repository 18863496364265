import { Activity, Reading, Movement } from "./Activity";
import ActivityTracker from "./ActivityTracker";

class Day {
  public readingMinutes: number = 0;
  public exerciseMinutes: number = 0;
  public activities: Activity[] = [];

  constructor(public date: Date, public activityTracker: ActivityTracker) {
    this.loadActivities();
  }

  loadActivities(): void {
    this.activities = this.activityTracker.getActivitiesForDay(this.date);
    this.activities.forEach((activity) => {
      if (activity instanceof Reading) this.readingMinutes += activity.duration;
      if (activity instanceof Movement) this.exerciseMinutes += activity.duration;
    });
  }
}

export default Day;

import { ReactNode, createContext, useContext, useState } from "react";
import User from "../domain/User";
import { AccountInfo } from "@azure/msal-browser";
import * as apiClient from "../api/apiClient";
import { User as UserDto } from "../../../common/model/user";
import { useAuth } from "./authContext";
import { useProgress } from "./progressContext";
import { ExportFormatType } from "../../../common/model/exportFormatType";

interface UserContextType {
  user: User | null;
  initializeUserAsync: () => Promise<User | null>;
  exportUserDataAsync: (format: ExportFormatType) => Promise<void>;
  exportAllDataAsync: (format: ExportFormatType) => Promise<void>;
}

export const UserContext = createContext<UserContextType>({
  user: null,
  initializeUserAsync: async () => null,
  exportUserDataAsync: async (_format: ExportFormatType) => {},
  exportAllDataAsync: async (_format: ExportFormatType) => {},
});

/**
 * @typedef UserContextProviderProps
 * @property {ReactNode} children - React components that will be rendered as children of the UserContextProviderProps.
 */
interface UserContextProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserContextProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const { acquireAccessTokenAsync, account } = useAuth();
  const { isInitializingUser, setInitializingUser } = useProgress();

  interface FormatExportedDataResult {
    formattedData: any;
    mimeType: string;
    fileExtension: string;
  }

  function formatExportedData(exportedData: string, format: ExportFormatType): FormatExportedDataResult {
    let mimeType = "";
    let fileExtension = "";

    let formattedData = exportedData;
    switch (format) {
      case "json":
        formattedData = JSON.parse(exportedData);
        mimeType = "application/json";
        fileExtension = ".json";
        break;
      case "csv":
        mimeType = "text/csv";
        fileExtension = ".csv";
        break;
    }

    return {
      formattedData,
      mimeType,
      fileExtension,
    };
  }

  function downloadFile(formattedData: string, mimeType: string, fileName: string) {
    const blob = new Blob([formattedData], { type: mimeType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    // Clean up
    document.body.removeChild(link);
    // Release the reference to the blob
    window.URL.revokeObjectURL(url);
  }

  const ensureUserExistsAsync = async () => {
    const createUserOf = (account: AccountInfo): UserDto => {
      return {
        userId: account.homeAccountId,
        displayName: account.name || "",
        email: account.username || "",
        userName: account.username || "",
      };
    };

    if (isInitializingUser()) {
      return null;
    }

    const userDto = createUserOf(account!);

    if (user && userDto.userId === user.id) {
      return user;
    }

    setInitializingUser(true);
    try {
      const user = await apiClient.ensureUserExistsAsync(() => acquireAccessTokenAsync(), userDto);
      const userRef = User.fromDto(user);
      setUser(userRef);
      return userRef;
    } finally {
      setInitializingUser(false);
    }
  };

  const getFormattedDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, "0")}-${String(
      currentDate.getDate()
    ).padStart(2, "0")}`;
    return formattedDate;
  };

  const exportUserDataAsync = async (format: ExportFormatType) => {
    try {
      const userDataExportString: string = await apiClient.exportUserDataAsync(() => acquireAccessTokenAsync(), format);

      const { formattedData, mimeType, fileExtension } = formatExportedData(userDataExportString, format);

      const formattedDate = getFormattedDate();
      downloadFile(formattedData, mimeType, `user-data-${formattedDate}${fileExtension}`);
    } catch (error) {
      console.error(error);
    }
  };

  const exportAllDataAsync = async (format: ExportFormatType) => {
    try {
      const exportedDataString: string = await apiClient.exportAllDataAsync(() => acquireAccessTokenAsync(), format);

      const { formattedData, mimeType, fileExtension } = formatExportedData(exportedDataString, format);

      const formattedDate = getFormattedDate();
      downloadFile(formattedData, mimeType, `all-data-${formattedDate}${fileExtension}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        initializeUserAsync: ensureUserExistsAsync,
        exportUserDataAsync,
        exportAllDataAsync,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within an UserProvider");
  }
  return context;
};
